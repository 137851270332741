import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import DrawerListt from './drawerListt';
import AppContext from '../../contexts/ServiceContext';
import useClickOutside from '../../hooks/useClickOutside';
const drawerWidth = 270;

function MenuAppBars(props) {
    const { userId, userName, valideLogin, titlePage } = React.useContext(AppContext)

    const { window } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [open, setOpen] = React.useState(false);

    const wrapper = React.useRef(null)

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const handleOpenDrawer = () => setOpen(prev => !prev);


    const drawerToggle = (
        <DrawerListt toggleDrawer={handleDrawerToggle} isOpen={true} />
    );
    const drawerStatic = (
        <DrawerListt toggleDrawer={()=>{} } isOpen={open} />
    );

    /* useClickOutside(wrapper, open ? handleCloseDrawer : undefined, [wrapper, open]); */

    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <div>
            {valideLogin ?
                <Box sx={{ display: "flex" }}>
                    <CssBaseline />
                    <AppBar
                        position="fixed"
                        sx={{
                            width: { sm: open ? `calc(100% - ${drawerWidth}px)` : `calc(100% - 100px)`},
                            ml: { sm: `${drawerWidth}px` },
                            boxShadow: 0,
                            backgroundColor: "#ff9800"

                        }}
                    >
                        <Toolbar>
                            <IconButton
                                color="primary"
                                aria-label="open drawer"
                                edge="start"
                                onClick={handleDrawerToggle}
                                sx={{ mr: 2, display: { sm: 'none' } }}
                            >
                                <MenuIcon />
                            </IconButton>

                            <IconButton
                                color="primary"
                                aria-label="open drawer"
                                edge="start"
                                onClick={handleOpenDrawer}
                                sx={{ mr: 2, display: { xs: "none", sm: 'block' } }}
                            >
                                <MenuIcon />
                            </IconButton>

                            <Typography sx={{ color: "textColorTitle", }} variant='h6'>
                                {userName} / {titlePage}
                            </Typography>

                        </Toolbar>
                    </AppBar>


                    <Box
                        component="nav"
                        sx={{overflow:"hidden", transition: "all 250ms ease", width: { sm: open ? drawerWidth : 100 }, flexShrink: { sm: 0 }, backgroundColor: "#fafafa" }}
                        aria-label="mailbox folders"
                    >
                        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}

                        <Drawer
                            container={container}
                            variant="temporary"
                            open={mobileOpen}
                            onClose={handleDrawerToggle}
                            ModalProps={{
                                keepMounted: true, // Better open performance on mobile.
                            }}
                            sx={{
                                display: { xs: 'block', sm: 'none' },
                                transition: "all 250ms ease",
                                '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, backgroundImage: `url(bg_user.png)`, backgroundRepeat: open ? "round" : "repeat", overflow:"hidden" },
                            }}
                        >
                            {drawerToggle}
                        </Drawer>
                        <Drawer
                            ref={wrapper}
                            variant="permanent"
                            sx={{
                                display: { xs: 'none', sm: 'block' },
                                '& .MuiDrawer-paper': { boxSizing: 'border-box', width: open ? drawerWidth : 100, backgroundImage: `url(bg_user.png)`, backgroundRepeat: open ? "round" : "repeat", overflow:"hidden" },
                            }}
                            open={open}
                        >
                            {drawerStatic}
                        </Drawer>
                    </Box>
                    <Box
                        component="main"
                        sx={{ minHeight: "100vh", paddingInline: "20px", paddingTop: "20px", width: "100%",overflow:"hidden", backgroundColor: "backgroundColorPage" }}
                    >
                        <Toolbar />
                        {props.children}
                    </Box>

                </Box >
                :
                <>
                    {props.children}
                </>
            }
        </div>
    );
}

MenuAppBars.propTypes = {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
};

export default MenuAppBars;









/*
import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { Drawer } from '@mui/material';
import MoreIcon from '@mui/icons-material/MoreVert';

export default function MenuAppBars() {
    const [isDrawerOpen ,setIsDrawerOpen ] = React.useState(false)
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={()=>setIsDrawerOpen(true)}
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Photos
          </Typography>
          <div>
          <IconButton
            size="large"
            aria-label="display more actions"
            edge="end"
            color="inherit"
          >
            <MoreIcon />
          </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer 
      anchor='left' 
      variant="permanent"
      open={isDrawerOpen} 
      onClose={()=>setIsDrawerOpen(false)}
      >
        <Box width={300}>

        </Box>
      </Drawer>
    </Box>
  );
}
*/
