import React, { useEffect, useContext, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import AppContext from '../../contexts/ServiceContext';
import { TITLEPAGE, URL_SERVER } from '../../contexts/constantesVar';
import { Box, Grid, Tab, Tabs } from '@mui/material';
import CajaMasterInfoGeneral from '../../components/cajaMasterInfo/cajaMasterInfoGeneral/cajaMasterInfoGeneral';
import FormInteresASaldoCaja from '../../components/cajaMasterInfo/interesASaldoCaja/FormInteresASaldoCaja';
import CardStadoSaldo from '../../components/cardStadoSaldo';


function CajaMasterInfo() {
    const { typeUser, valideLogin, userId, userName, userCode, userPhone, dispatch, acciones } = useContext(AppContext)
    const [value, setValue] = useState(1);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const { id } = useParams()

    useEffect(() => {
        if (JSON.parse(window.localStorage.getItem("enableTAdmins"))) {
        } else {
            window.localStorage.setItem("enableTAdmins", JSON.stringify({ valor: false, valorI: "", nameI: '', typeI: '', phoneI: '' }))
        }
        dispatch({
            type: TITLEPAGE,
            payload: "INFORMACION DE LA CAJA"
        })
    }, [])

    return (
        <>
            <CardStadoSaldo />
            
            <Grid
                spacing={1}
                paddingTop={4}
                paddingX={4}
                bgcolor="backgroundColorPage"
                container
            >
                <CajaMasterInfoGeneral id={id} />

            </Grid>


        </>
    )

}

export default CajaMasterInfo