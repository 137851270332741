import * as React from 'react';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MailIcon from '@mui/icons-material/Mail';
import CallReceivedIcon from '@mui/icons-material/CallReceived';
import InfoIcon from '@mui/icons-material/Info';
import SendIcon from '@mui/icons-material/Send';
import SettingsIcon from '@mui/icons-material/Settings';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { Add, Home, PersonAddAlt1, WhatsApp } from '@mui/icons-material';
import { PeopleAlt } from '@mui/icons-material';
import { Sort } from '@mui/icons-material';
import { Sync, Phone } from '@mui/icons-material';
import LockIcon from '@mui/icons-material/Lock';
import { HouseSharp } from '@mui/icons-material';
import { NavLink } from 'react-router-dom';
import FeedIcon from '@mui/icons-material/Feed';
import { Box, Button } from '@mui/material';
import { ID_USER, NAME_USER, PHONE_USER, TYPE_USER, VALIDE_USER } from '../../contexts/constantesVar';
import AppContext from '../../contexts/ServiceContext';
import {useActivePath} from '../../hooks';

const drawerWidth = 200;

const listIconStyle = {
    /* color: "textColorTitle2", */
    fontSize: '27px',
    fontWeight: "600px"
}



function DrawerListt({ toggleDrawer, isOpen }) {
    const { userId, dispatch, typeUser, valideLogin } = React.useContext(AppContext)

    const activePath = useActivePath();

    const CloseSesion = () => {
        window.localStorage.setItem("enableTAdmins", JSON.stringify({ valor: false, valorI: "", nameI: '', typeI: '', phoneI: '', accI: [] }))

        dispatch({
            type: VALIDE_USER,
            payload: false
        })
        dispatch({
            type: NAME_USER, 
            payload: ""
        })

        dispatch({
            type: ID_USER,
            payload: ""
        })


        dispatch({
            type: TYPE_USER,
            payload: ""
        })
        dispatch({
            type: PHONE_USER,
            payload: ""
        })

        window.localStorage.setItem("tokenGnop", "")
        window.localStorage.setItem("qsaw", "")

    }
    const listePath = [
        { text: 'Inicio', link: "/", path: "/" },
        { text: 'Enviar', link: "/enviar", path: "/enviar" },
        { text: 'Recibir', link: "/recibir", path: "/recibir" },
        { text: 'Transacciones', link: "/transaccion/1", path: "/transaccion" },
        { text: 'Flujo de saldo', link: "/flujo/1", path: "/flujo" },
        { text: 'Info de la caja', link: `/caja_master_info/${userId}`, path: "/caja_master_info" },
        //{ text: 'Cambiar contrasena', link: "/cambiar_password" },
    ]
    return (
        <>
            {valideLogin ?
                <div className='nav-list'> 
                    <Box title="G-NOB MONEY" sx={{display: "flex", justifyContent: "center"}}>
                
                        <Box sx={{
                            marginTop: 3,
                            flexDirection: 'column',
                            alignItems: "center",
                            justifyContent: "center",
                            display: "flex",
                        }}>
                            <Box
                            sx={{
                            flexDirection: 'column',
                            alignItems: "center",
                            justifyContent: "center",
                            display: "flex",
                            borderRadius: "50%",
                            width: isOpen ? 120 : 70,
                            height: isOpen ? 120 : 70,
                            bgcolor: "#fff"
                            }}
                            >
                                <img
                                src={'logo2.png'}
                                alt={"gnob"}
                                loading="lazy"
                                width={isOpen ? 105 : 60}

                            />
                            </Box>
                            {isOpen ? <Typography sx={{ color: "#eee", textAlign: 'center', marginTop: 1 }} variant='h5'>
                                G-NOB MONEY
                            </Typography> : <></>}
                        </Box>

                    </Box>

                    <List sx={{pt: 4, display: 'flex', flexDirection: "column", gap: '8px', paddingInline: '10px'}}>    
                        {listePath.map((menu, index) => (
                            <ListItem key={menu.text} disablePadding divider={true} sx={{ color: "#212121", fontSize: '30px', fontWeight: "800px" }}>
                                <ListItemButton 
                                component={NavLink} 
                                to={menu.link}  
                                onClick={toggleDrawer} 
                                sx={{
                                justifyContent: isOpen ? 'flex-start' : 'center', 
                                backgroundColor: activePath(menu.path) ? "#f5f5f5" : "#022162",
                                borderRadius: 2,
                                '&:hover': {
                                    backgroundColor: '#f5f5f5' 
                                },
                                '&:hover > .MuiListItemIcon-root': {
                                    color: '#212121'
                                },
                                '&:hover > .MuiListItemText-root': {
                                    color: '#212121'
                                },
                                }}
                                >
                                    <ListItemIcon sx={{minWidth: isOpen ? "48px" : 0, color: activePath(menu.path) ? "#212121" : "#f5f5f5"}}>
                                        {menu.text === "Inicio" ? <Home sx={listIconStyle} /> : <></>}
                                        {menu.text === "Enviar" ? <SendIcon sx={listIconStyle} /> : <></>}
                                        {menu.text === "Transacciones" ? <Sync sx={listIconStyle} /> : <></>}
                                        {menu.text === "Flujo de saldo" ? <Sort sx={listIconStyle} /> : <></>}
                                        {menu.text === "Cambiar contrasena" ? <SettingsIcon sx={listIconStyle} /> : <></>}
                                        {menu.text === "Info de la caja" ? <InfoIcon sx={listIconStyle} /> : <></>}
                                        {menu.text === "Recibir" ? <CallReceivedIcon sx={listIconStyle} /> : <></>}
                                    </ListItemIcon>
                                    {isOpen ? <ListItemText primary={menu.text} sx={{color: activePath(menu.path) ? "#212121" : "#f5f5f5"}} /> : null}
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                    <List sx={{paddingInline: '10px'}}> 
                        <ListItem disablePadding divider={true} sx={{ color: "#212121", marginTop: 2, fontSize: '30px', fontWeight: "800px" }}>
                            <ListItemButton 
                            onClick={() => { CloseSesion() }} 
                            sx={{
                                justifyContent: isOpen ? 'flex-start' : 'center', 
                                backgroundColor: "red",
                                borderRadius: 2,
                                    '&:hover': {
                                        backgroundColor: 'red' 
                                    },
                                    '&:hover > .MuiListItemIcon-root': {
                                        color: '#ffffff'
                                    },
                                    '&:hover > .MuiListItemText-root': {
                                        color: '#ffffff'
                                },
                            }}
                            >
                                <ListItemIcon  sx={{minWidth: isOpen ? "48px" : 0, color: "#ffffff"}}>
                                    <LockIcon sx={listIconStyle} />
                                </ListItemIcon>
                                {isOpen ? <ListItemText primary={'Cerra sesion'} sx={{color: "#ffffff"}} /> : null}
                            </ListItemButton>
                        </ListItem>
                    </List>
                    {isOpen ? (
                        <Box sx={{paddingInline: '10px', paddingBlock: 2, color: "#f5f5f5"}}>
                            <Typography sx={{width: "100%", textAlign: "center", fontSize: "18px"}}>Contáctanos</Typography>
                            <Box sx={{pt: 1, display: "flex", alignItems: "center", justifyContent: "center"}}>
                                <Phone  sx={{color: "#ffffff"}}/> 
                                <Typography sx={{pl: 1}}>222666565</Typography>
                            </Box>

                            <Box sx={{pt: 1, display: "flex", alignItems: "center", justifyContent: "center"}}>
                                <WhatsApp sx={{color: "#00ff00"}}/> 
                                <Typography sx={{pl: 1}}>222666565</Typography>
                            </Box>
                        </Box>
                    ) : <></>}
                    
                </div>
                :
                <div></div>
            }
        </>
    )
}

export default DrawerListt