import { ThemeProvider, createTheme,responsiveFontSizes } from '@mui/material/styles';
import { esES } from '@mui/material/locale';


const TemaGlobals = createTheme({
  get components() {
      return {
        MuiButton: {
          styleOverrides: {
            root: ({ownerState}) => ({
              '&:hover': {
                backgroundColor: this.palette[ownerState.color]?.light,
                color: this.palette[ownerState.color]?.color,
              }
            })
          }
        }
      }
  },
  palette: {
    primary: {
      main:'#022162',
      light: '#0235A1',
      color: '#f5f5f5'
    },
    textColorTitle:"#424242",
    textColorTitle2:"#eeeeee",
    backgroundColorPage:"#f1f1f1",
    principalColor:"#022162",
    
  }
},
esES
);

export const TemaGlobal = responsiveFontSizes(TemaGlobals)