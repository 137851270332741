import React from 'react'
import { Box, Typography } from '@mui/material'

export default function PrintedItem({label, value}) {
  return (
    <Typography variant='p' sx={{fontWeight: "500", display: "flex", alignItems: {xs: "flex-start", sm: "center"}, flexDirection: {xs: "column", sm: "row"}}}>
        <Box sx={{ color: '#212121', fontSize: '15px', minWidth: {xs: '180px'}, flexShrink: 0}}>{label} : </Box>
        <Box sx={{paddingBlock: 1, paddingInline: 2, backgroundColor: "#eeeeee", borderRadius: 2, flexShrink: 0, mt: {xs: 1, sm: 0}, ml: 1}}>{value}</Box>
    </Typography>
  )
}
