import React, { useEffect, useContext, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import AppContext from '../contexts/ServiceContext';

import { Box, Grid } from '@mui/material';
import CardHome from './cardHome';
import SkelethonCard from './skelholder/skelethonCardHome';
import useSWR from "swr"
import { GetDataHome, GetDataHomeMaster } from '../pages/home/getDataHome';
import addComaToNumber from '../helper/addComaToNumber';


function CardStadoSaldo() {
  const arrayMaster = ['Master_GNOB']

  const { userId,AxiosConfigsToken } = useContext(AppContext)

  const { data, error, isLoading, } = useSWR(["getDataHomeCajaStado", userId], () => GetDataHomeMaster(userId,AxiosConfigsToken), {})

  return (
    <>
      {true ?
        <Grid
          spacing={4}
          paddingInline={4}
          bgcolor="backgroundColorPage"

          container
        >

          {isLoading ?
            <>
              {
                error ?
                  <></>
                  :
                  <SkelethonCard />

              }
            </>
            :
            <>
              {data ?
                <CardHome IconHome={""} colors='#f7f0d9' boxShadow={8} colorText='#212121' cantidad={data.quantSolde ? addComaToNumber(data.quantSolde) + ' ' + 'XAF' : 0} colorIcon='#607d8b' titleCard="Total Saldo" />

                :
                <></>
              }
            </>
          }


          {isLoading ?
            <>
              {
                error ?
                  <></>
                  :
                  <SkelethonCard />

              }
            </>
            :
            <>
              {data ?
                <CardHome IconHome={""} colors='#f7f0d9' boxShadow={8} colorText='#212121' cantidad={data.interesSocio ? addComaToNumber(data.interesSocio) + ' ' + 'XAF' : 0} colorIcon='#607d8b' titleCard="Total Interes" />

                :
                <></>
              }
            </>
          }

        </Grid>
        :
        <></>
      }


    </>
  )

}

export default CardStadoSaldo