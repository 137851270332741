


//export const URL_SERVER = process.env.NODE_ENV = 'production'?process.env.REACT_APP_API_URL:process.env.REACT_APP_API_LOCAL_URL
export const URL_SERVER = process.env.REACT_APP_API_URL
//export const URL_SERVER = process.env.REACT_APP_API_LOCAL_URL
//export const URL_SERVER = 'https://lobster-app-ly4wv.ondigitalocean.app/'

//export const KEY_UI = process.env.REACT_APP_KEY_UI
export const VALIDE_USER = "valideLogin"
export const NAME_USER = "userName"
export const ID_USER = "userId"
export const PHONE_USER = "userPhone"
export const CODE_USER = "userCode"
export const PERMISION_SEND_TRANSFER = "permisionSendTransfer"
export const PERMISION_PAYMENTS = "permisionPayments"
export const LOGIN_SPINNER = "loginSpinner"
export const RESP_ERROR_LOGIN = "errorResponseLogin"     
export const ERROR_USER = "userError" 
export const TYPE_USER = "typeUser"   
export const ACCIONES = "acciones"     
export const TITLEPAGE = "titlePage"
export const PORCENTAGE = "porcentage"     
export const TOKEN = "token"     
export const SALDO_EFECTIVO = "saldo_efectivo" 
export const SALDO = "saldo" 
export const INTERES_G = "interes_g" 
export const INTERES_S = "interes_s" 
export const IVA = "iva" 




