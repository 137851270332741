import React, { useState, useContext, useEffect } from 'react'
import MenuAppBars from '../../components/appBar/appBarr'
import { Grid, Tab, Tabs, Box, InputAdornment, TextField, Autocomplete, Typography, FormControl, InputLabel, Select, MenuItem, Button, Card, CardContent, Modal, CircularProgress } from '@mui/material';
import { LoadingButton, TabContext, TabPanel } from '@mui/lab';
import Envios from '../../components/transacciones/envios/envios';
import Recepciones from '../../components/transacciones/recepciones/recepciones';
import AppContext from '../../contexts/ServiceContext';
import { useForm } from 'react-hook-form';
import { AccountCircle } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { jsPDF } from "jspdf";
import LinearProgress from '@mui/material/LinearProgress';
import { useSWRConfig } from 'swr';
import { Comisiones } from './comisiones';
import axiosConfigs from '../../components/axiosConfig';
import CardStadoSaldo from '../../components/cardStadoSaldo';
import { TITLEPAGE } from '../../contexts/constantesVar';
import addComaToNumber from '../../helper/addComaToNumber';


function Enviar() {
    const { typeUser, valideLogin, userId, userName, activeSendTransfer, userPhone, dispatch, acciones, porcentage, AxiosConfigsToken } = useContext(AppContext)
    const { mutate } = useSWRConfig()

    const [enviosBuscados, setEnviosBuscados] = useState([]);
    const [cargaEnvio, setCargaEnvio] = useState(false);
    const [cargaEnvio2, setCargaEnvio2] = useState(false);
    const [load, setLoad] = useState(false);
    const [nameSend, setNameSend] = useState('');
    const [nameSendBB, setNameSendBB] = useState(true);
    const [phoneSend, setPhoneSend] = useState('');
    const [phoneSendBB, setPhoneSendBB] = useState(true);
    const [quantSend, setQuantSend] = useState('');
    const [quantSendBB, setQuantSendBB] = useState(true);
    const [dipSend, setDipSend] = useState('');
    const [dipSendBB, setDipSendBB] = useState(true);
    const [phoneRecep, setPhoneRecep] = useState('');
    const [phoneRecepBB, setPhoneRecepBB] = useState(true);
    const [adressRecep, setAdressRecep] = useState('');
    const [adressRecepBB, setAdressRecepBB] = useState(true);
    const [nameRecep, setNameRecep] = useState('');
    const [nameRecepBB, setNameRecepBB] = useState(true);
    const [numberSearch1, setNumberSearch1] = useState('');
    const [numberSearch1BB, setNumberSearch1BB] = useState(true);
    const [numberSearch2, setNumberSearch2] = useState('');
    const [numberSearch2BB, setNumberSearch2BB] = useState(true);
    const [totalCobrar, setTotalCobrar] = useState(0);
    const [totalPagar, setTotalPagar] = useState(0);
    const [totalComision, setTotalComision] = useState(0);

    const handleChangeAutoComplete = (value) => {
        //setAdressRecep(`${value.adressRecep}`)
        setNameSend(`${value.nameSend}`)
        setPhoneSend(`${value.phoneSend}`)
        setDipSend(`${value.dipSend}`)
        //setQuantSend(`${value.quantSend}`)

    }

    const handleChangeAutoComplete02 = (value) => {
        //setQuantSend(`${value.quantSend}`)
        setPhoneRecep(`${value.phoneSend}`)
        setNameRecep(`${value.nameSend}`)
    }


    const doc = new jsPDF("p", "pt", "b6");

    const GenerarPdf = async () => {
        //console.log("first")
        var content = document.querySelector("#facturaSend")
        await doc.html(content, {
            callback: (pdf) => {
                pdf.save("factura.pdf")
            }
        });
    }

    //el useForm de react form hook
    const {
        register,
        handleSubmit,
        control,
        setValue,
        formState: { errors }
    } = useForm();

    //Funcion que se llama despues dpulsar el boton submit
    const HacerEnvio = async () => {
        let data = {}

        data.idAdmin = userId
        data.phoneAdmin = userPhone
        data.nameAdmin = userName
        data.adressRecep = adressRecep
        data.nameSend = nameSend
        data.phoneSend = numberSearch1
        data.dipSend = dipSend
        data.quantSend = quantSend
        data.phoneRecep = numberSearch2
        data.nameRecep = nameRecep

        const phoneCode1 = numberSearch1.slice(0, 3) === "222" || numberSearch1.slice(0, 3) === "333" || numberSearch1.slice(0, 3) === "555" || numberSearch1.slice(0, 3) === "551" || numberSearch1.slice(0, 3) === "666" || numberSearch1.slice(0, 3) === "550"
        const phoneCode2 = numberSearch2.slice(0, 3) === "222" || numberSearch2.slice(0, 3) === "333" || numberSearch2.slice(0, 3) === "555" || numberSearch2.slice(0, 3) === "551" || numberSearch2.slice(0, 3) === "666" || numberSearch2.slice(0, 3) === "550"
        const phoneLengNumber1 = numberSearch1.length === 9 && Number(numberSearch1)
        const phoneLengNumber2 = numberSearch2.length === 9 && Number(numberSearch2)
        const quantLengNumber = quantSend.length >= 4 && Number(quantSend)


        const validar = data.nameRecep && data.phoneRecep && quantLengNumber && data.dipSend && data.phoneSend && data.nameSend && data.adressRecep && data.nameAdmin && data.phoneAdmin && data.idAdmin

        if (validar && phoneCode1 && phoneCode2 && phoneLengNumber1 && phoneLengNumber2) {


            confirmAlert({
                customUI: ({ onClose }) => {
                    return (

                        <div className='container-dialog-confirm' >
                            <div id='' >
                                <Typography sx={{ color: "red", textAlign: "center" }} variant='h5'>
                                    Porfavor revisa !
                                </Typography>
                                <p><span style={{ fontSize: 16, color: "#616161" }}>Nombre del remitente:</span> <span style={{ fontWeight: "700", fontSize: 16 }}> {nameSend}</span></p>
                                <p><span style={{ fontSize: 16, color: "#616161" }}>Telefono del remitente:</span> <span style={{ fontWeight: "700", fontSize: 16 }}> {numberSearch1}</span></p>
                                <p><span style={{ fontSize: 16, color: "#616161" }}>DIP/Pasaporte del remitente:</span> <span style={{ fontWeight: "700", fontSize: 16 }}> {dipSend}</span></p>
                                <p><span style={{ fontSize: 16, color: "#616161" }}>Cantidad enviado:</span> <span style={{ fontWeight: "700", fontSize: 16 }}> {Number(quantSend).toLocaleString("es-GQ")} XAF</span></p>
                                <p><span style={{ fontSize: 16, color: "#616161" }}>Ciudad del receptor:</span> <span style={{ fontWeight: "700", fontSize: 16 }}> {adressRecep}</span></p>
                                <p><span style={{ fontSize: 16, color: "#616161" }}>Nombre del receptor:</span> <span style={{ fontWeight: "700", fontSize: 16 }}> {nameRecep}</span></p>
                                <p><span style={{ fontSize: 16, color: "#616161" }}>Telefono del receptor:</span> <span style={{ fontWeight: "700", fontSize: 16 }}> {numberSearch2}</span></p>
                            </div>
                            <div className='container-dialog-confirm-button'>
                                <Button size='small' variant="contained" className='btn-small' sx={{bgcolor: "red"}} onClick={onClose}>Cancelar</Button>
                                <Button
                                    size='small'
                                    variant="contained"
                                    sx={{ marginLeft: 3, backgroundColor: "#2e7d32" }}
                                    onClick={async () => {


                                        try {
                                            setLoad(true)
                                            const envio = await AxiosConfigsToken({ url: `/enviar`, method: "post", data })


                                            if (envio.data.verificar) {

                                                mutate(["getDataHomeCajaStado", userId])

                                                confirmAlert({ 
                                                    customUI: ({ onClose }) => {
                                                        const mes = Number(new Date(envio.data.result.fechaA).getMonth()) + 1;
                                                        const dia = Number(new Date(envio.data.result.fechaA).getDate());
                                                        const agno = Number(new Date(envio.data.result.fechaA).getFullYear());
                                                        const hora = new Date(envio.data.result.fechaA).getHours();
                                                        const min = new Date(envio.data.result.fechaA).getMinutes();

                                                        const fecha = dia + '/' + mes + '/' + agno + '  ' + hora + ':' + min;
                                                        return (

                                                            <div className='container-dialog-confirm' >

                                                                <div id='facturaSend' style={{ marginLeft: 10, marginTop: 0 }} >
                                                                    <div style={{ display: 'flex', width: "100%", alignItems: "center", marginBottom: -10 }}>
                                                                        <div style={{ width: "60%", display: 'flex' }}>
                                                                            <img src="https://res.cloudinary.com/mumbex/image/upload/v1697882076/ahb4n32vt8pnabzd9voa.png" style={{ width: "150px" }} alt="" />
                                                                        </div>
                                                                        <div style={{ width: "30%" }}>
                                                                            <h3>G-NOB</h3>
                                                                            <h3 style={{ marginTop: '-20px' }}>MONEY</h3>
                                                                        </div>
                                                                    </div>
                                                                    <h3 style={{ marginBlock: -7 }}>FACTURA DE ENVIO</h3>

                                                                    <p style={{ marginBlock: 1 }}><span style={{ fontSize: 12, fontWeight: '700', color: '#000000' }}>Fecha de envio:</span> <span style={{ fontWeight: "700", color: '#000000', fontSize: 12 }}>{fecha}</span></p>
                                                                    <p style={{ marginBlock: 1 }}><span style={{ fontSize: 12, fontWeight: '700', color: '#000000' }}>Ciudad de envio:</span> <span style={{ fontWeight: "700", color: '#000000', fontSize: 12 }}>{envio.data.result.adressAdmin}</span></p>
                                                                    <p style={{ marginBlock: 1 }}><span style={{ fontSize: 12, fontWeight: '700', color: '#000000' }}>Punto de envio:</span> <span style={{ fontWeight: "700", color: '#000000', fontSize: 12 }}>{envio.data.result.adressGettoSend}</span></p>
                                                                    <p style={{ marginBlock: 1 }}><span style={{ fontSize: 12, fontWeight: '700', color: '#000000' }}>Tel.. del Agente:</span> <span style={{ fontWeight: "700", color: '#000000', fontSize: 12 }}>{envio.data.result.phoneAdmin}</span></p>

                                                                    <h4 style={{ marginBlock: -6, marginTop: 3, color: '#000000' }}>DATOS DEL REMITENTE</h4>

                                                                    <p style={{ marginBlock: 1 }}><span style={{ fontSize: 12, fontWeight: '700', color: '#000000' }}>Nombre del remitente:</span> <span style={{ fontWeight: "700", fontSize: 14, color: '#000000' }}>{envio.data.result.nameSend}</span></p>
                                                                    <p style={{ marginBlock: 1 }}><span style={{ fontSize: 12, fontWeight: '700', color: '#000000' }}>Tel. del remitente:</span> <span style={{ fontWeight: "700", fontSize: 14, color: '#000000' }}>{envio.data.result.phoneSend}</span></p>
                                                                    <p style={{ marginBlock: 1 }}><span style={{ fontSize: 12, fontWeight: '700', color: '#000000' }}>DIP/Pass del remitente:</span> <span style={{ fontWeight: "700", fontSize: 14, color: '#000000' }}>{envio.data.result.dipSend}</span></p>
                                                                    <p style={{ marginBlock: 1 }}><span style={{ fontSize: 12, fontWeight: '700', color: '#000000' }}>Cantidad enviada:</span> <span style={{ fontWeight: "700", fontSize: 14, color: '#000000' }}>{Number(envio.data.result.quantSend).toLocaleString("es-GQ")} XAF</span></p>

                                                                    <h4 style={{ marginBlock: -6, marginTop: 3, color: '#000000' }}>DATOS DEL BENEFICIARIO</h4>

                                                                    <p style={{ marginBlock: 1 }}><span style={{ fontSize: 12, fontWeight: '700', color: '#000000' }}>Ciudad de recepcion:</span> <span style={{ fontWeight: "700", fontSize: 14, color: '#000000' }}>{envio.data.result.adressRecep}</span></p>
                                                                    <p style={{ marginBlock: 1 }}><span style={{ fontSize: 12, fontWeight: '700', color: '#000000' }}>Nombre del receptor:</span> <span style={{ fontWeight: "700", fontSize: 14, color: '#000000' }}>{envio.data.result.nameRecep}</span></p>
                                                                    <p style={{ marginBlock: 1 }}><span style={{ fontSize: 12, fontWeight: '700', color: '#000000' }}>Tel. del receptor:</span> <span style={{ fontWeight: "700", fontSize: 14, color: '#000000' }}>{envio.data.result.phoneRecep}</span></p>
                                                                    <p style={{ marginBlock: 1 }}><span style={{ fontSize: 12, fontWeight: '700', color: '#000000' }}>Codigo de recepcion:</span> <span style={{ fontWeight: "700", fontSize: 14, color: '#000000' }}>{envio.data.result.codeRecp}</span></p>

                                                                </div>

                                                                <div style={{ marginLeft: 10 }} >

                                                                    <Button
                                                                        size='small'
                                                                        variant="contained"
                                                                        color='error'
                                                                        onClick={onClose}>Cerrar</Button>
                                                                    <Button
                                                                        size='small'
                                                                        variant="contained"
                                                                        sx={{ marginLeft: 3 }}
                                                                        onClick={async () => {
                                                                            GenerarPdf()
                                                                        }}
                                                                    >
                                                                        Descargar pdf
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                        );
                                                    },

                                                });
                                                setAdressRecep(``)
                                                setNameSend(``)
                                                setPhoneSend(``)
                                                setDipSend(``)
                                                setQuantSend(``)
                                                setPhoneRecep(``)
                                                setNameRecep(``)
                                                //setEnviosBuscados([])
                                                toast.success(`${envio.data.mens}`)
                                                setLoad(false)
                                                onClose();

                                            } else {
                                                setLoad(false)
                                                toast.error(`${envio.data.mens}`)

                                            }

                                        } catch (error) {
                                            setLoad(false)
                                            toast.error(`Hay un problema`)

                                        }

                                    }}
                                >
                                    Realizar el envio
                                </Button>
                            </div>
                        </div>
                    );
                },

            });

        } else {
            if (!dipSend) {
                setDipSendBB(false)
            } else {
                setDipSendBB(true)
            }
            if (!nameRecep) {
                setNameRecepBB(false)
            } else {
                setNameRecepBB(true)
            }
            if (!nameSend) {
                setNameSendBB(false)
            } else {
                setNameSendBB(true)
            }
            if (!((phoneLengNumber1) && (phoneCode1))) {

                setNumberSearch1BB(false)
            } else {
                setNumberSearch1BB(true)
            }
            if (!((phoneLengNumber2) && (phoneCode2))) {
                setNumberSearch2BB(false)
            } else {
                setNumberSearch2BB(true)
            }
            if (!quantLengNumber) {
                setQuantSendBB(false)
            } else {
                setQuantSendBB(true)
            }
            if (!adressRecep) {
                setAdressRecepBB(false)
            } else {
                setAdressRecepBB(true)
            }

        }


    }

    const BuscarEnvios = async (clave) => {
        setNumberSearch1(clave)
        if (Number(clave) && clave.length === 9) {
            //console.log(clave)
            setCargaEnvio(true)

            const buscar = await AxiosConfigsToken({ url: `/buscar_envios`, method: "post", data: { clave: clave } })

            if (clave.length === 9) {
                try {
                    if (buscar.data.verificar) {
                        //console.log(buscar.data.data.docs)
                        //setEnviosBuscados(buscar.data.data.docs)
                        setCargaEnvio(false)
                        handleChangeAutoComplete(buscar.data.data.docs[0])
                    } else {
                        setNameSend(``)
                        setPhoneSend(``)
                        setDipSend(``)
                        setCargaEnvio(false)
                    }
                } catch (error) {
                    setNameSend(``)
                    setPhoneSend(``)
                    setDipSend(``)
                    setCargaEnvio(false)
                }
            }
        } else {
            setNameSend(``)
            setPhoneSend(``)
            setDipSend(``)
            setCargaEnvio(false)
        }

    }
    const BuscarEnvios01 = async (clave) => {
        setNumberSearch2(clave)
        if (Number(clave) && clave.length === 9) {
            //console.log(clave)
            setCargaEnvio2(true)

            const buscar = await AxiosConfigsToken({ url: `/buscar_envios`, method: "post", data: { clave: clave } })

            if (clave.length === 9) {
                try {
                    if (buscar.data.verificar) {
                        //console.log(buscar.data.data.docs)
                        //setEnviosBuscados(buscar.data.data.docs)
                        setCargaEnvio2(false)
                        handleChangeAutoComplete02(buscar.data.data.docs[0])
                    } else {
                        setCargaEnvio2(false)
                        setPhoneRecep(``)
                        setNameRecep(``)
                    }
                } catch (error) {
                    setCargaEnvio2(false)
                    setPhoneRecep(``)
                    setNameRecep(``)
                }
            }
        } else {
            setCargaEnvio2(false)
            setPhoneRecep(``)
            setNameRecep(``)
        }

    }

    const CalcularComision = (valors) => {
        //console.log(typeUser,'userType')
        if (Number(valors)) {
            const comisiones = Comisiones(valors, typeUser, porcentage)
            try {
                setTotalCobrar(Number(comisiones.total) + Number(valors))
                setTotalComision(comisiones.total)
                setTotalPagar(Number(valors))
            } catch (error) {

            }

        } else {
            setTotalCobrar(0)
            setTotalComision(0)
            setTotalPagar(0)
        }

    }

    const LimpiarForm = () => {
        setNumberSearch1('')
        setNumberSearch2('')
        setPhoneRecep('')
        setNameRecep('')
        setNameSend('')
        setPhoneSend('')
        setAdressRecep('')
        setDipSend('')
        setTotalCobrar(0)
        setTotalComision(0)
        setTotalPagar(0)
    }


    useEffect(() => {

        if (JSON.parse(window.localStorage.getItem("enableTAdmins"))) {
        } else {
            window.localStorage.setItem("enableTAdmins", JSON.stringify({ valor: false, valorI: "", nameI: '', typeI: '', phoneI: '' }))
        }
        dispatch({
            type: TITLEPAGE,
            payload: "FORMULARIO DE ENVIO DE DINERO"
        })
    }, [])
    return (
        <>
            {valideLogin ?
                <>
                    <CardStadoSaldo />
                    <form style={{ marginTop: "20px" }}>
                        <Grid
                            spacing={4}
                            paddingInline={4}
                            bgcolor="backgroundColorPage"
                            container
                        >

                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}  >
                                <Box>
                                    <Typography sx={{ color: "textColorTitle", textAlign: "center" }} variant='h5'>
                                        DATOS DEL REMITENTE
                                    </Typography>
                                </Box>
                                <div style={{ width: '100%', marginTop: 15 }}>
                                    <TextField
                                        label="Telefono del remitente"
                                        id="outlined-size-small"
                                        size="medium"
                                        value={numberSearch1}
                                        onChange={(e) => { BuscarEnvios(e.target.value) }}
                                        sx={{ width: "100%" }}
                                        //{...register("phoneSend", { required: "Campo requerido", minLength: 9, maxLength: 9 })}
                                        //error={!!errors?.phoneSend}
                                        error={numberSearch1BB ? false : true}
                                    />
                                    {cargaEnvio ?
                                        <Box sx={{ width: '100%' }}>
                                            <LinearProgress />
                                        </Box>
                                        :
                                        <></>
                                    }

                                </div>
                                <div style={{ width: '100%', marginTop: 15 }}>
                                    <TextField
                                        label="Nombre del remitente"
                                        id="outlined-size-small-name-s"
                                        size="medium"
                                        sx={{ width: "100%" }}
                                        value={nameSend}
                                        onChange={(e) => { setNameSend(e.target.value) }}

                                        //{...register("nameSend", { required: "Campo requerido", minLength: 4 })}
                                        //error = {!!errors?.nameSend}
                                        error={nameSendBB ? false : true}

                                    />
                                </div>

                                <div style={{ width: '100%', marginTop: 15 }}>
                                    <TextField
                                        label="El DIP/Pasaporte del remitente"
                                        id="outlined-size-small"
                                        size="medium"
                                        value={dipSend}
                                        onChange={(e) => { setDipSend(e.target.value) }}
                                        sx={{ width: "100%" }}
                                        //{...register("dipSend", { required: "Campo requerido", minLength: 4 })}
                                        //error={!!errors?.dipSend}
                                        error={dipSendBB ? false : true}


                                    />
                                </div>
                                <div style={{ width: '100%', marginTop: 15 }}>
                                    <TextField
                                        label="El monto a enviar"
                                        id="outlined-size-small"
                                        size="medium"
                                        value={quantSend}
                                        onChange={(e) => {
                                            if (Number(e.target.value)) {
                                                if (Number(e.target.value) <= 2000000) {
                                                    setQuantSend(e.target.value);
                                                    CalcularComision(e.target.value)
                                                } else {

                                                }

                                            } else {
                                                setQuantSend("");
                                                setTotalCobrar(0)
                                                setTotalComision(0)
                                                setTotalPagar(0)
                                            }
                                        }}
                                        sx={{ width: "100%" }}
                                        //{...register("quantSend", { required: "Campo requerido", minLength: 1, min: 1000 })}
                                        error={quantSendBB ? false : true}


                                    />
                                </div>
                                <Box
                                    sx={{
                                        width: "100%",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        mt:2
                                    }}
                                >
                                    <Card sx={{ width: "100%", marginTop: 1, backgroundColor: '#dddddd' }}>
                                        <CardContent sx={{display: "flex", flexDirection: "column", gap: 2}}>
                                            <Typography variant='p' sx={{fontWeight: "500", display: "flex", alignItems: {xs: "flex-start", sm: "center"}, flexDirection: {xs: "column", sm: "row"}}}>
                                                <Box sx={{ color: '#212121', fontSize: '16px', minWidth: {xs: '150px'}, flexShrink: 0}}>Total comision:</Box>
                                                <Box sx={{paddingBlock: 1, paddingInline: 2, backgroundColor: "#eeeeee", borderRadius: 2, flexShrink: 0, mt: {xs: 1, sm: 0}, ml: 1}}>{addComaToNumber(totalComision)} XAF</Box>
                                            </Typography>
                                            <Typography variant='p' sx={{fontWeight: "500", display: "flex", alignItems: {xs: "flex-start", sm: "center"}, flexDirection: {xs: "column", sm: "row"}}}>
                                                <Box sx={{ color: '#212121', fontSize: '16px', minWidth: {xs: '150px'}, flexShrink: 0}}>Total a pagar:</Box>
                                                <Box sx={{paddingBlock: 1, paddingInline: 2, backgroundColor: "#eeeeee", borderRadius: 2, flexShrink: 0, mt: {xs: 1, sm: 0}, ml: 1}}>{addComaToNumber(totalCobrar)} XAF</Box>
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} xl={6}  >
                                <Box>
                                    <Typography sx={{ color: "textColorTitle", textAlign: "center", marginTop: { xs: "12px", sm: "12px", xl: "0px" } }} variant='h5'>
                                        DATOS DEL BENEFICIARIO
                                    </Typography>
                                </Box>


                                <div style={{ width: '100%', marginTop: 15 }}>
                                    <TextField
                                        label="Telefono del beneficiario"
                                        id="outlined-size-small"
                                        size="medium"
                                        value={numberSearch2}
                                        onChange={(e) => { BuscarEnvios01(e.target.value) }}
                                        sx={{ width: "100%" }}
                                        //{...register("phoneSend", { required: "Campo requerido", minLength: 9, maxLength: 9 })}
                                        //error={!!errors?.phoneSend}
                                        error={numberSearch2BB ? false : true}


                                    />
                                    {cargaEnvio2 ?
                                        <Box sx={{ width: '100%' }}>
                                            <LinearProgress />
                                        </Box>
                                        :
                                        <></>
                                    }
                                </div>
                                <div style={{ width: '100%', marginTop: 15 }}>
                                    <TextField
                                        label="Nombre del beneficiario"
                                        id="outlined-size-small"
                                        onChange={(e) => { setNameRecep(e.target.value) }}
                                        value={nameRecep}
                                        size="medium"
                                        sx={{ width: "100%" }}
                                        //{...register("nameRecep", { required: "Campo requerido", minLength: 4 })}
                                        //error={!!errors?.nameRecep}
                                        error={nameRecepBB ? false : true}


                                    />
                                </div>

                                <div style={{ width: '100%', marginTop: 15 }}>
                                    <FormControl sx={{ width: "100%" }}>
                                        <InputLabel id="demo-simple-select-label">Ciudad del receptor</InputLabel>
                                        <Select
                                            id="demo-simple-select-adress-r"
                                            label="Ciudad del receptor"
                                            //{...register("adressRecep", { required: true })}
                                            //error={!!errors?.adressRecep}
                                            onChange={(e) => { setAdressRecep(e.target.value) }}
                                            value={adressRecep}
                                            error={adressRecepBB ? false : true}
                                        >

                                            <MenuItem value="Malabo" >Malabo</MenuItem>
                                            <MenuItem value="Bata" >Bata</MenuItem>
                                            <MenuItem value="Mongomo" >Mongomo</MenuItem>
                                            <MenuItem value="Ebibeyin" >Ebibeyin</MenuItem>
                                            <MenuItem value="Annobon" >Annobon</MenuItem>
                                            <MenuItem value="Riaba" >Riaba</MenuItem>
                                            <MenuItem value="Luba" >Luba</MenuItem>
                                            <MenuItem value="Moka" >Moka</MenuItem>
                                            <MenuItem value="Mbini" >Mbini</MenuItem>
                                            <MenuItem value="Cogo" >Cogo</MenuItem>
                                            <MenuItem value="Niefang" >Niefang</MenuItem>
                                            <MenuItem value="Akurenam" >Akurenam</MenuItem>
                                            <MenuItem value="Evinayong" >Evinayong</MenuItem>


                                            <MenuItem value="Mongomeyeng" >Mongomeyeng</MenuItem>

                                            <MenuItem value="Micomiseng" >Micomiseng</MenuItem>
                                            <MenuItem value="Anisok" >Anisok</MenuItem>
                                            <MenuItem value="Oyala" >Oyala</MenuItem>
                                            <MenuItem value="Nsork" >Nsork</MenuItem>
                                            <MenuItem value="Akonibe" >Akonibe</MenuItem>
                                            <MenuItem value="Nsok-Nzomo" >Nsok-Nzomo</MenuItem>
                                            <MenuItem value="Nkue" >Nkue</MenuItem>

                                        </Select>
                                    </FormControl>
                                    <Box
                                        sx={{
                                            width: "100%",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            mt:2
                                        }}
                                    >
                                        <Card sx={{ width: "100%", marginTop: 1, backgroundColor: '#dddddd' }}>
                                            <CardContent sx={{display: "flex", flexDirection: "column", gap: 2}}>
                                                <Typography variant='p' sx={{fontWeight: "500", display: "flex", alignItems: {xs: "flex-start", sm: "center"}, flexDirection: {xs: "column", sm: "row"}}}>
                                                    <Box sx={{ color: '#212121', fontSize: '16px', minWidth: {xs: '150px'}, flexShrink: 0}}> Total a cobrar:</Box>
                                                    <Box sx={{paddingBlock: 1, paddingInline: 2, backgroundColor: "#eeeeee", borderRadius: 2, flexShrink: 0, mt: {xs: 1, sm: 0}, ml: 1}}>{addComaToNumber(totalPagar)} XAF</Box>
                                                </Typography>
                                            </CardContent>
                                        </Card>
                                    </Box>
                                    <Box sx={{ width: { sm: '100%', md: '100%', lg: '100%' }, display: 'flex', flexDirection: 'row', marginBottom: 2 }}>
                                        <div style={{ width: '49%', marginTop: 20 }}>
                                            <Button
                                                onClick={() => { LimpiarForm() }}
                                                variant="contained"
                                                color="error"
                                                sx={{ width: "100%", marginRight: 1 }}
                                                size="medium"
                                            >
                                                Cancelar
                                            </Button>
                                        </div>
                                        <div style={{ width: '49%', marginTop: 20 }}>
                                             <LoadingButton
                                                onClick={() => HacerEnvio()}
                                                loading={load}
                                                variant="contained"
                                                color="success"
                                                sx={{ width: "100%", marginLeft: 1 }}
                                                size="medium"
                                                disabled={activeSendTransfer}
                                            >
                                                <span>Enviar</span>
                                            </LoadingButton>
                                        </div>
                                    </Box>
                                </div>
                            </Grid>
                        </Grid>
                    </form>
                </>
                :
                <></>
            }
            <Modal
            open={load}
            onClose={() => setLoad(false)}
            sx={{display: "flex", justifyContent: "center", alignItems: "center"}}
            >
                <CircularProgress style={{height: '100px', width: "100px", zIndex: 3, color: "#ffffff"}} />
            </Modal>
        </>
    )
}


export default Enviar