import {useLocation} from 'react-router-dom';


export function useActivePath(){
  const {pathname} = useLocation();

  const checkActivePath = (path) => {
    if (path === "/" && pathname !== path) return false;

    return pathname.startsWith(path);
  };

  return checkActivePath;
}